import React from "react";
import { connect } from "react-redux";
import { InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";
import { CardBody, Card, CardHeader, Form, FormGroup, Label } from "reactstrap";
import { withrawChild } from "./redux/action";
import { showPasswordOverlay } from "../../global/redux/action";
import FootprintInputRadio from "../../global/utils/FootprintInputRadio";
import FootprintDatePicker from "../../global/utils/FootprintDatePicker";
import FootprintButton from "../../global/utils/FootprintInputButton";
import FootprintInputText from "../../global/utils/FootprintInputText";
import { DEFAULT_FORMAT } from "../../global/redux/combineLogic";
import moment from "moment";
import Survey from "../Survey/component/Survey";
class ChildWithdraw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      //withdrawDate: undefined,
      reason: undefined,
      otherRemark: undefined,
      rating: undefined,
      tags: undefined,
      comment: undefined,
      withdrawDate: undefined
    };
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  validate = () => {
    if (this.state.holidaySelected === true) {
      this.setState({ error: "The Selected Date Cannot Be A Holiday" });
      return false;
    } else if (this.state.withdrawDate === undefined) {
      this.setState({ error: "Please Select Date Of Withdraw" });
      return false;
    } else if (this.state.reason === undefined) {
      this.setState({ error: "Please Select Reason" });
      return false;
    } else if (
      this.state.reason === "Others" &&
      this.state.otherRemark === undefined
    ) {
      this.setState({ error: "Please Other Remark Incase of Other" });
      return false;
    } else if (this.state.rating === undefined) {
      this.setState({ error: "Please Select Rating" });
      return false;
    } else if (this.state.tags === undefined || this.state.tags?.length === 0) {
      this.setState({ error: "Please Check The Tags" });
      return false;
    } else if (this.state.comment === undefined || this.state.comment === "") {
      this.setState({ error: "Please Enter Comment" });
      return false;
    } else {
      return true;
    }
  };

  submit = (type) => {
    if (this.validate()) {
      // this.props.showPasswordOverlay(true, (isMatched) => {
      //   if (isMatched) {
      this.props.withrawChild(
        {
          withdrawDate: this.state.withdrawDate,
          reason: this.state.reason,
          otherRemark: this.state.otherRemark,
          rating: this.state.rating,
          tags: this.state.tags,
          comment: this.state.comment,
        },
        () => {
          this.props.history.replace("support");

        }
      );
    } else {
    }
    //   });
    // }
  };
  render() {
    console.log("[Child Withdraw] Request for child attrition...");
    return (
      <div>
        <Card className="border-0 shadow  card-timeline mt-4">
          <CardHeader
            className="text-white"
            style={{
              background: "#A500F8",
              fontFamily: "baloo",
              fontSize: "1.3em",
            }}
          >
            Child Withdraw
          </CardHeader>
          <CardBody>
            <Card body className="border-0 shadow">
              <Form>
                <FormGroup>
                  <Label for="startDate">Withdraw Date</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-calender" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <FootprintDatePicker
                      holidaySelected={(selected) => {
                        this.setState({
                          error: "The selected Date Cannot Be a Holiday",
                          holidaySelected: selected,
                        });
                      }}
                      inputProps={{ placeholder: "Select Date" }}
                      minimumDate={moment().subtract(1, 'days')}
                      onChange={(date) => {
                        this.setState({
                          holidaySelected: false,
                          withdrawDate: moment(date).format(DEFAULT_FORMAT),
                          error: undefined,
                        });
                      }}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup tag="fieldset">
                  <legend className="col-form-label">Reason Option</legend>
                  {this.props.constants.WithdrawReason.map((item, i) => (
                    <div key={i}>
                      <FootprintInputRadio
                        className="mb-2"
                        label={item}
                        value={item}
                        name="customRadio"
                        id={i}
                        onClick={(value) => {
                          this.setState(
                            { reason: value, error: undefined },
                            () => { }
                          );
                        }}
                      />
                    </div>
                  ))}
                </FormGroup>
                {this.state.reason === "Others" && (
                  <FormGroup>
                    <Label for="exampleText">Reason</Label>

                    <FootprintInputText
                      type="text"
                      name="text"
                      id="exampleText"
                      onChange={(value) => {
                        this.setState(
                          { otherRemark: value, error: undefined },
                          () => { }
                        );
                      }}
                    />
                  </FormGroup>
                )}
                <Survey
                  onRatingPressed={(rating) =>
                    this.setState({ rating, error: undefined })
                  }
                  getComment={(comment) =>
                    this.setState({ comment, error: undefined })
                  }
                  getTags={(answers) => {
                    this.setState({
                      tags: answers.filter((item) => item.checked === true),
                      error: undefined,
                    });
                  }}
                />
                {typeof this.state.error !== "undefined" && (
                  <p
                    style={{
                      color: "red",
                    }}
                  >
                    {this.state.error}
                  </p>
                )}
                <FormGroup className="mb-0 text-center">
                  <FootprintButton
                    className="btn btn-primary"
                    onClick={() => {
                      this.submit();
                    }}
                  />
                </FormGroup>
              </Form>
            </Card>
          </CardBody>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({ constants: state.constants });

const mapDispatchToProps = (dispatch) => ({
  showPasswordOverlay: (show, onSuccess) =>
    dispatch(showPasswordOverlay(show, onSuccess)),
  withrawChild: (payload, onSuccess) =>
    dispatch(withrawChild(payload, onSuccess)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChildWithdraw);
