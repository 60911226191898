//build environments
let randomNumber = Math.floor(Math.random() * 100);
const LOCAL = {
  type: 'LOCAL',
  filePath: '/config_2_0.json',
  lambdaApiServer: "http://localhost:4000/local",
  AmazonAPI: "http://footprints-parentapp-ui-prod.s3-website-ap-southeast-1.amazonaws.com",
  AppUrl: "https://y6idvn45ge.execute-api.us-east-1.amazonaws.com/dev",
  cctvConnectionCheck:
    "https://admissions.footprintseducation.in/parents/site-json/Uptimerobot_listCentresStatus.json",
  BlogsJSONApi: "https://parentapp.s3.ap-southeast-1.amazonaws.com/blog_posts.json",
  version: "4.2.0",
  platform: "web",
  web: "yes"
};

const DEV = {
  type: 'DEV',
  //filePath: '/config_2_0.json',
  filePath: `https://parentapp.s3.ap-southeast-1.amazonaws.com/AppStagingConfig.json?version=${randomNumber}`,
  lambdaApiServer: "https://qvas3cc2q0.execute-api.ap-southeast-1.amazonaws.com/dev", //"https://y6idvn45ge.execute-api.us-east-1.amazonaws.com/dev",
  AmazonAPI: "http://footprints-parentapp-ui.s3-website-us-east-1.amazonaws.com",
  AppUrl: "https://qvas3cc2q0.execute-api.ap-southeast-1.amazonaws.com/dev",
  cctvConnectionCheck: `https://fp-portal-uploads.s3.ap-southeast-1.amazonaws.com/uploads/site-json/Uptimerobot_listCentresStatus.json?version=${randomNumber}`,
  // cctvConnectionCheck:
  //   `https://admissions.footprintseducation.in/parents/site-json/Uptimerobot_listCentresStatus.json?version=${randomNumber}`,
  BlogsJSONApi: `https://fp-portal-uploads.s3.ap-southeast-1.amazonaws.com/uploads/site-json/blog_posts.json?version=${randomNumber}`,
  //BlogsJSONApi: `https://parentapp.s3.ap-southeast-1.amazonaws.com/blog_posts.json?version=${randomNumber}`,
  platform: "web",
  web: "yes",
  version: "4.5.0",
};

const TEST = {
  type: 'TEST',
  filePath: 'https://staging-parents.footprintseducation.in/stagingConfigV2.json',
  lambdaApiServer: "https://y6idvn45ge.execute-api.us-east-1.amazonaws.com/dev",
  AmazonAPI: "http://footprints-parentapp-ui-prod.s3-website-ap-southeast-1.amazonaws.com",
  AppUrl: "https://y6idvn45ge.execute-api.us-east-1.amazonaws.com/dev",
  cctvConnectionCheck:
    "https://admissions.footprintseducation.in/parents/site-json/Uptimerobot_listCentresStatus.json",
  BlogsJSONApi:
    "https://parentapp.s3.ap-southeast-1.amazonaws.com/blog_posts.json",
  version: "4.2.0",
  platform: "web",
  web: "yes"
}

const STAGING = {
  type: 'STAGING',
  filePath: 'https://staging-parents.footprintseducation.in/stagingConfigV2.json',
  lambdaApiServer: "https://y6idvn45ge.execute-api.us-east-1.amazonaws.com/dev",
  AmazonAPI: "http://footprints-parentapp-ui-prod.s3-website-ap-southeast-1.amazonaws.com",
  AppUrl: "https://y6idvn45ge.execute-api.us-east-1.amazonaws.com/dev",
  cctvConnectionCheck:
    "https://admissions.footprintseducation.in/parents/site-json/Uptimerobot_listCentresStatus.json",
  BlogsJSONApi: "https://parentapp.s3.ap-southeast-1.amazonaws.com/blog_posts.json",
  version: "4.4.0",
  platform: "android",
  web: "no"
}

const PRODUCTION = {
  type: 'PRODUCTION',
  filePath: `https://parentapp.s3.ap-southeast-1.amazonaws.com/AppProdConfig.json?version=${randomNumber}`, //'https://parent-connect.footprintseducation.in/config_2_0.json'
  lambdaApiServer: "https://40bxy7a7v3.execute-api.ap-southeast-1.amazonaws.com/prod",
  AmazonAPI: "https://parent-connect.footprintseducation.in",
  AppUrl: "https://40bxy7a7v3.execute-api.ap-southeast-1.amazonaws.com/prod",

  cctvConnectionCheck: `https://fp-portal-uploads.s3.ap-southeast-1.amazonaws.com/uploads/site-json/Uptimerobot_listCentresStatus.json?version=${randomNumber}`,
  // cctvConnectionCheck: `https://admissions.footprintseducation.in/parents/site-json/Uptimerobot_listCentresStatus.json?version=${randomNumber}`,
  BlogsJSONApi: `https://fp-portal-uploads.s3.ap-southeast-1.amazonaws.com/uploads/site-json/blog_posts.json?version=${randomNumber}`,
  //BlogsJSONApi: `https://parentapp.s3.ap-southeast-1.amazonaws.com/blog_posts.json?version=${randomNumber}`,
  // version: "3.2.1",
  platform: "web",
  version: "4.5.0",
  // platform: "web",
  web: "yes"
}

export default { DEV, TEST, STAGING, PRODUCTION, LOCAL }