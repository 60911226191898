import { setLoading, setError, setInfo, showModal } from "../redux/action";
import moment from "moment";
import crypto from "crypto";
import { addLogMessage, recordExceptionWithStacktrace } from "./crashlyticsMethods";
import { logout } from "../../features/Login/redux/action";
import { App } from "@capacitor/app";
import Constants from "../../constants";

let getCurrentDate = () => {
  return moment().utc().format("YYYY-MM-DD");
};

export const generateAuthToken = () => {
  const saltKey = "!foot@prints#education#";
  let token = getCurrentDate() + "_" + saltKey;
  return crypto.createHash("md5").update(token).digest("hex");
}

export const getAuthToken = () => {
  let auth_token = localStorage.getItem("auth_token").replace(/["']/g, '');
  if (auth_token) {
    return auth_token;
  } else {
    return generateAuthToken();
  }
};

const fetchAPI = ({
  url,
  method,
  body,
  getState,
  dispatch,
  done,
  queryParams,
  action,
  noHeader = false,
  errorReportType = "none",
  successReportType = "none",
  successMessage,
  loading = setLoading,
  authenticatedAPI = true,
  returnText = false,
}) =>
  new Promise((resolve, reject) => {
    // let token = '';
    if (authenticatedAPI) {
      // if (getState().userState.user && getState().userState.user.userName) { token = base64.encode(`${getState().userState.user.userName}:${getState().userState.user.reportKey}`).toString('base64'); } else
      //  {
      //   dispatch(loading(false));
      //   dispatch(setInfo("Error ", "Not authenticated with server."));
      //   reject('User is not Authenticated');
      // }
    }
    const activeChildDetail =
      getState().userState &&
      getState().userState.user &&
      getState().userState.user.activeChildDetail;

    const parent_type = getState().userState?.parentProfile?.parentType;

    if (
      getState().userState &&
      getState().userState.user &&
      getState().userState.user.activeChildDetail &&
      method !== "GET"
    ) {
      const activeChildDetail = getState().userState.user.activeChildDetail;
      //const id = getID();
      if (activeChildDetail) {
        const center = activeChildDetail.center_id;
        // const child_id = id;
        const child_id = activeChildDetail.id;
        const center_id = activeChildDetail.center_id;
        const room_id = activeChildDetail.room_id;
        body = { ...body, center, center_id, child_id, room_id };
      }
    }

    let formBody = [];
    if (body) {
      for (const property in body) {
        const key = property;
        const value = body[property];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            formBody.push(`${key}=${item}`);
          });
        } else {
          if (typeof value !== "undefined") {
            formBody.push(`${key}=${value}`);
          }
        }
      }
      formBody = formBody.join("&");

    }
    const queryString = queryParams
      ? Object.keys(queryParams)
        .map((key) => key + "=" + queryParams[key])
        .join("&")
      : undefined;
    //const urlQuery = (queryString ? url + '/?' + queryString : url);
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    if (noHeader === false) {
      headers.auth_token = getAuthToken();
      if (activeChildDetail && localStorage.getItem('auth_token') && parent_type) {
        headers.child_id = activeChildDetail.id;
        headers.parent = parent_type;
      }
      headers.platform = Constants.env.platform;
      headers.app_version = Constants.env.version;
    }
    // console.log("Fetching Started Url ",url)
    dispatch(loading(true));
    fetch(url, {
      method,
      body: body ? formBody : undefined,
      headers,
    })
      .then(async (response) => {
        // throw new Error("Fetching Started Url");
        // console.log("CCTV down response =>",response)
        if (response.ok) {
          if (response.status === 200) {
            if (!returnText) {
              return response.json();
            }
            return response.text();
          }
          return response;
        }
        if (response.status === 401) {
          try {
          } catch (error) {
            done();
          }
        } else {
          //const error = Constants.getErrorMessage(response.status);
          // if (action.type !== LOG_ERROR) {
          //   // if (getState().userState.user && getState().userState.user.userName) {
          //   //   dispatch(logError(`${action.type}, ${JSON.stringify(response)}`));
          //   // }
          // }
          // otherwise logerror will contnously call log error (infinite loop)
          const errorMessage = `Server Error When ${action.type.toLowerCase()} : ${moment
            .utc()
            .format()}`;
          dispatch(setInfo("", errorMessage));
          reject(errorMessage);
          dispatch(loading(false));
          //done();
        }
      })
      .then(async (response) => {
        // throw new Error("Fetching Started Url");

        if (response) {
          dispatch(loading(false));
          resolve(response);
          // console.log(
          //   "API ",
          //   url,
          //   "the body is ",
          //   formBody,
          //   "the header is ",
          //   headers,
          //   "the response is ",
          //   response
          // );

          if (response.status === "ok") {
            const message =
              successMessage ||
              response.message ||
              response.response ||
              "Request Submitted Sucessfully";
            // console.log("the message is ", message, successReportType);
            if (typeof message === "string") {
              if (successReportType === "Alert") {
                dispatch(showModal("Success", message));
              } else if (successReportType !== "none") {
                dispatch(setInfo("Success", message));
              }
            }
            // resolve(response);
            // errorReportType = "Alert";
          } else if (response.status === "error") {
            const message = response.error || response.message;
            //console.log("the Error message is ", message);
            if (errorReportType === "Alert") {
              dispatch(showModal("Error", message));
            } else if (errorReportType !== "none") {
              dispatch(setInfo("Failed", message));
            }
            if (response.response == "Expired Auth Token.") {
              dispatch(showModal("Error", "Session expired! Please login again.", false, async () => {
                await dispatch(logout());
                await App.removeAllListeners();
                localStorage.setItem("auth_token", "");
                window.location.href = `/`;
              }));
            }
            if (
              response?.error ==
              "It seems you are using older version. Update to its latest"
            ) {
              dispatch(
                showModal(
                  "Error",
                  "It seems you are using older version. Update to its latest.",
                  false,
                  async () => {
                    // await dispatch(logout());
                    // await App.removeAllListeners();
                    // localStorage.setItem("auth_token", "");
                    // window.location.href = `/`;
                  }
                )
              );
            }
          }
          // done();
        } else {
          reject("The request was successful but API didn't gave any response");
          addLogMessage(
            `Request url ${url} with body ${body} was successful but API didn't gave any response`
          );
          dispatch(loading(false));
          done();
        }
      })
      .catch((error) => {
        reject(`An error has occurred: ${error.message}`);
        dispatch(setError("Some Error Occurred", error.message));
        dispatch(loading(false));
        addLogMessage(`Request url ${url} with body ${body} error occuered ${error.message}`);
        recordExceptionWithStacktrace(error);
        done();
      });
  });

export default fetchAPI;
