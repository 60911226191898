import React from "react";
import { connect } from "react-redux";
// import { CustomInput } from "reactstrap";

class CORDetailItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: []
    };
  }

  // with this function we create an array with the opened collapses

  render() {
    const { data, openedCollapses, setCollapse, index, last } = this.props;
    // console.log("Data: ", data);
    // let msg = data.value.replace(/<\/?br>/g, "\n");
    // msg = msg.replace(/[\\/]/g, "");

    let msg = typeof data.value === "string" ? data.value.replace(/<\/?br>/g, "\n").replace(/[\\/]/g, "") : "";

    // console.log("MSG: ", msg);
    return (
      <div
        id="accordion"
        style={last ? { borderBottom: "0.5px solid lightgray" } : {}}
      >
        <div className="card">
          <div
            className="card-header d-flex"
            style={{ borderBottom: 0 }}
            id="headingOne"
            onClick={(e) => setCollapse(e, index)}
          >
            <div className="mr-auto text-truncate">
              <h3
                className={`mb-0 btn btn-link ${openedCollapses.includes(index) ? "text-wrap" : ""
                  }`}
                style={{ fontSize: "16px", padding: 0, color: "#525f7f" }}
              >
                {data.key}
              </h3>
            </div>
            <div className="d-flex align-items-center">
              <i
                className={`${openedCollapses.includes(index)
                  ? "icon-arrow_up"
                  : "icon-arrow-down"
                  } `}
              ></i>
            </div>
          </div>

          {openedCollapses.includes(index) ? (
            <div
              id="collapseOne"
              className="collapse show pb-3"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div className="card-body" style={{ whiteSpace: "pre-line", padding: '0 1.5rem' }}>
                {msg}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      // <div>
      //   <p style={{ fontFamily: "Baloo" }}>{data.key}</p>
      //   <CustomInput
      //     type="checkbox"
      //     id="exampleCustomCheckbox"
      //     label={msg}
      //     checked
      //     readOnly
      //   />
      // </div>
    );
  }
}
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(CORDetailItem);
