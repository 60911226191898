import React from "react";
import { connect } from "react-redux";
import {
  CustomInput,
  CardHeader,
  CardBody,
  Card,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap";
import { fetchSurvey, surveyCheckboxAnswer } from "../redux/action";
import FootprintInputCheckbox from "../../../global/utils/FootprintCheckBox";
import FootprintInputText from "../../../global/utils/FootprintInputText";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";

const RIGHT_QUESTION = "What can we do for a better recommendation from you?";
const WRONG_QUESTION = "Please tell us what you like most about footprints.";

class Survey extends React.PureComponent {
  static navigationOptions = ({ navigation }) => ({
    header: null
  });
  constructor(props) {
    super(props);
    this.state = {
      selectedRating: -1,
      dynamicQuestions: undefined,
      selectedItem: null,
      selectedCheckboxes: {},

    };
  }

  componentDidMount() {
    this.props.fetchSurvey();
  }




  ratingPressed = (rating) => {
    const { selectedItem } = this.state;

    const newSelectedItem = this.props.questions.find(
      (item) => rating <= parseInt(item.cat_max) && rating >= parseInt(item.cat_min)
    );


    const isCatKeyChanged = selectedItem?.cat_key !== newSelectedItem?.cat_key;

    this.setState({
      selectedRating: rating,
      selectedItem: newSelectedItem,
      selectedCheckboxes: isCatKeyChanged ? {} : this.state.selectedCheckboxes,
    });


    if (isCatKeyChanged && this.props.getTags) {
      this.props.getTags([]);
    }

    if (this.props.onRatingPressed) {
      this.props.onRatingPressed(rating);
    }
  };



  handleCheckboxClick = (items) => {
    const selectedItems = items.filter(item => item.checked);

    if (this.state.selectedItem) {
      const updatedSelections = {
        ...this.state.selectedCheckboxes,
        [this.state.selectedItem.cat_key]: selectedItems
      };

      this.setState({ selectedCheckboxes: updatedSelections });

      if (this.props.getTags) {
        this.props.getTags(selectedItems);
      }
    }
  };


  render() {
    const {
      questions //light = false
    } = this.props;
    if (Array.isArray(questions) && questions.length > 0) {
      return (
        <div>
          <FormGroup>
            <legend className="col-form-label">
              How likely you would recommend Footprints to your friends and
              colleagues?
            </legend>
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between">
                {[...Array(10)].map((e, index) => (
                  <div key={index}
                    onClick={() => this.ratingPressed(index + 1)}
                    className={`btn btn-outline-primary p-1 p-sm-2 text-xs text-md-md p-lg-3 d-flex align-items-center justify-content-center ${this.state.selectedRating == index + 1 ? 'bg-primary text-white' : ''}`}
                    style={{ borderRadius: '100%', height: '1.28rem', width: '1.28rem' }}>
                    <input
                      type="radio"
                      name="rating"
                      value={index + 1}
                      style={{ display: "none" }}
                    />
                    {index + 1}
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-between text-xs mt-2">
                <div><FaLongArrowAltLeft style={{ fontSize: 18, color: '#5e72e4' }} /> Least likely</div>
                <div>Most likely <FaLongArrowAltRight style={{ fontSize: 18, color: '#5e72e4' }} /></div>
              </div>
            </div>
          </FormGroup>

          {this.state.selectedRating > 0 && this.state.selectedItem && (
            <Card className="mb-3">
              <CardHeader className="text-center h5" style={{ lineHeight: 'normal' }}>
                {this.state.selectedItem.cat_slug}
              </CardHeader>
              <CardBody>
                <FootprintInputCheckbox
                  key={this.state.selectedItem.cat_key}
                  data={this.state.selectedItem.data.map(item => ({
                    value: item,
                    label: item.value,
                    checked: (this.state.selectedCheckboxes[this.state.selectedItem.cat_key] || []).some(
                      (selected) => selected.value === item.value
                    )
                  }))}
                  onClick={this.handleCheckboxClick}
                />
              </CardBody>
            </Card>
          )}

          <FormGroup>
            {this.state.selectedRating !== -1 && (
              <div>
                <Label for="exampleText">
                  {this.state.selectedRating < 9
                    ? RIGHT_QUESTION
                    : WRONG_QUESTION}
                </Label>
                <FootprintInputText
                  type="text"
                  onChange={text => {
                    if (this.props.getComment) {
                      this.props.getComment(text);
                    }
                  }}
                />
              </div>
            )}
          </FormGroup>
        </div>
      );
    }

    return <div />;
  }
}

const mapStateToProps = state => ({
  questions: state.survey.questions
});

const mapDispatchToProps = dispatch => ({
  fetchSurvey: () => dispatch(fetchSurvey()),
  surveyCheckboxAnswer: answer => dispatch(surveyCheckboxAnswer(answer))
});

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
